<template>
    <div>
        <b-card>
            <b-form-group>
                <!-- <b-form-select
                    id="i-regional"
                    v-model="selected"
                    :options="modules.colaboradores"
                    value-field="nome"
                    text-field="nome"
                    class="form-control"
                /> -->
                <v-select
                    v-model="selected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="nome"
                    :options="modules.colaboradores"
                />
            </b-form-group>
        </b-card>
        <b-row v-if="selected != null" class="ranking-style overflow-auto text-center">
            <b-col lg="4" :key="indextr" v-for="(tr, indextr) in this.teste">
                <b-card :title="tr.produto">
                    <b-row>
                        <b-col v-if="tr.ranking_valor != null">
                            <h1>
                                {{tr.ranking_valor.ranking}}º
                            </h1>
                            <b>
                                <h5>Valor {{tr.ranking_valor.valor | dinheiro}}</h5>
                            </b>
                        </b-col>
                        <b-col v-if="tr.ranking_quantidade != null">
                            <h1>
                                {{tr.ranking_quantidade.ranking}}º
                            </h1>
                            <b>
                                <h5>Qtd. {{tr.ranking_quantidade.quantidade }}</h5>
                            </b>
                        </b-col>
                        <b-col v-if="tr.ranking_media != null">
                            <h1>
                                {{tr.ranking_media.ranking}}º
                            </h1>
                            <b>
                                <h5>Média {{tr.ranking_media.media | media }}</h5>
                            </b>
                        </b-col>
                        <b-col v-if="tr.ranking_indice != null">
                            <h1>
                                {{tr.ranking_indice.ranking}}º
                            </h1>
                            <b>
                                <h5>Indice {{tr.ranking_indice.indice}}</h5>
                            </b>
                        </b-col>
                        <b-col v-if="tr.ranking_valor == null && tr.ranking_quantidade == null && tr.ranking_media == null && tr.ranking_indice == null">
                            Encontramos nenhum dado correspondente sobre o produto <b>{{tr.produto}}</b> em nosso sistema!
                        </b-col>
                    </b-row>
                </b-card>
                
                <!-- <div v-if="tr.ranking_valor != null">
                    <b-card :title="tr.produto">
                        <h1>
                            {{tr.ranking_valor.ranking}}º
                        </h1>
                        <b>
                            <h5>Valor {{tr.ranking_valor.valor | dinheiro}}</h5>
                        </b>
                    </b-card>
                </div>
                <div v-if="tr.ranking_quantidade != null">
                    <b-card :title="tr.produto">
                        <h1>
                            {{tr.ranking_quantidade.ranking}}º
                        </h1>
                        <b>
                            <h5>Qtd. {{tr.ranking_quantidade.quantidade }}</h5>
                        </b>
                    </b-card>
                </div>
                <div v-if="tr.ranking_media != null">
                    <b-card :title="tr.produto">
                        <h1>
                            {{tr.ranking_media.ranking}}º
                        </h1>
                        <b>
                            <h5>Média {{tr.ranking_media.media | media }}</h5>
                        </b>
                    </b-card>
                </div>
                <div v-if="tr.ranking_indice != null">
                    <b-card :title="tr.produto">
                        <h1>
                            {{tr.ranking_indice.ranking}}º
                        </h1>
                        <b>
                            <h5>Indice {{tr.ranking_indice.indice | media}}</h5>
                        </b>
                    </b-card>
                </div> -->

            </b-col>
            <!-- <b-card :key="indextr" v-for="(tr, indextr) in this.teste">
                <b-row>
                    <b-col lg="4" v-if="tr.ranking_valor != null || tr.ranking_quantidade != null || tr.ranking_media != null || tr.ranking_indice != null">
                        <div v-if="tr.ranking_valor != null">
                            <b-card :title="tr.produto">
                                <h1>
                                    {{tr.ranking_valor.ranking}}º
                                </h1>
                                <b>
                                    <h5>Valor {{tr.ranking_valor.valor | dinheiro}}</h5>
                                </b>
                            </b-card>
                        </div>
                        <div v-if="tr.ranking_quantidade != null">
                            <b-card :title="tr.produto">
                                <h1>
                                    {{tr.ranking_quantidade.ranking}}º
                                </h1>
                                <b>
                                    <h5>Qtd. {{tr.ranking_quantidade.quantidade }}</h5>
                                </b>
                            </b-card>
                        </div>
                        <div v-if="tr.ranking_media != null">
                            <b-card :title="tr.produto">
                                <h1>
                                    {{tr.ranking_media.ranking}}º
                                </h1>
                                <b>
                                    <h5>Média {{tr.ranking_media.media | media }}</h5>
                                </b>
                            </b-card>
                        </div>
                        <div v-if="tr.ranking_indice != null">
                            <b-card :title="tr.produto">
                                <h1>
                                    {{tr.ranking_indice.ranking}}º
                                </h1>
                                <b>
                                    <h5>Indice {{tr.ranking_indice.indice | media}}</h5>
                                </b>
                            </b-card>
                        </div>
                    </b-col>
                </b-row>
                <div v-if="tr.ranking_valor != null || tr.ranking_quantidade != null || tr.ranking_media != null || tr.ranking_indice != null">
                    <h5 class="text-center">{{tr.produto}}</h5>
                    <div class="row" v-if="tr.ranking_valor != null || tr.ranking_quantidade != null || tr.ranking_media != null || tr.ranking_indice != null">
                        <div class="col" v-if="tr.ranking_valor != null">
                            <b-card>
                                <h1>
                                    {{tr.ranking_valor.ranking}}º
                                </h1>
                                <b>
                                    <h5>Valor {{tr.ranking_valor.valor | dinheiro}}</h5>
                                </b>
                            </b-card>
                        </div>
                        <div class="col" v-if="tr.ranking_quantidade != null">
                            <b-card>
                                <h1>
                                    {{tr.ranking_quantidade.ranking}}º
                                </h1>
                                <b>
                                    <h5>Qtd. {{tr.ranking_quantidade.quantidade }}</h5>
                                </b>
                            </b-card>
                        </div>
                        <div class="col" v-if="tr.ranking_media != null">
                            <b-card>
                                <h1>
                                    {{tr.ranking_media.ranking}}º
                                </h1>
                                <b>
                                    <h5>Média {{tr.ranking_media.media | media }}</h5>
                                </b>
                            </b-card>
                        </div>
                        <div class="col" v-if="tr.ranking_indice != null">
                            <b-card>
                                <h1>
                                    {{tr.ranking_indice.ranking}}º
                                </h1>
                                <b>
                                    <h5>Indice {{tr.ranking_indice.indice | media}}</h5>
                                </b>
                            </b-card>
                        </div>
                    </div>
                    <div v-else>
                        <h5 class="text-danger">Nada informado</h5>
                    </div>
                </div>
                <div v-else>
                    <b-card class="text-center">
                        <h5>
                        Encontramos nenhum dado correspondente sobre o produto <b>{{tr.produto}}</b> em nosso sistema!
                        </h5>
                    </b-card>
                </div> -->
                
            <!--</b-card> -->
        </b-row>
        <b-card v-else class="text-center">
            <h4 class="mb-3">Selecione um colaborador</h4>
            <b-img
                fluid
                width="300"
                :src="require('@/assets/images/pages/investimentos.svg')"
            />
        </b-card>
    </div>
</template>
<script>
/* eslint-disable */    
import { BRow, BCol, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";

export default {
    components: {
        BRow,
        BCol,
        BFormGroup,
        vSelect,
        mapActions,
        mapState,
    },
    data() {
        return {
            selected: null,
            teste: null,
        }
    },
    computed: {
        ...mapState("connectAPI", ["modules"]),
    },
    methods: {
        fetchModules(paginated = false) {
            this.isLoading = true;
            this.$store
                .dispatch("connectAPI/fetchModules", {
                pathAPI: "colaboradores",
                paginated: paginated,
                })
                .then((response) => {
                })
                .catch((err) => {
                console.error(err);
                })
                .finally(() => {
                this.isLoading = false;
                });
        },
        fetchRanking(paginated = true, page = 1){
            this.isLoading = true
            this.$store
                .dispatch("connectAPI/fetchRankingTop", {
                paginated: false,
                page: page,
                top: 0,
                colaborador: this.selected.nome,
                per_page: this.perPage
                })
                .then((response) => {
                    this.teste = response
                    this.totalRows = response.total
                })
                .catch((err) => {
                console.error(err)
                })
                .finally(() => {
                this.isLoading = false
                })
        },
    },
    created(){
        this.fetchModules();
    },
    watch: {
        selected(){
            this.fetchRanking();
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.card-01-style {
  width: 100%;
  height: 250px;
}
.ranking-style {
  height: 45rem;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(75, 74, 74, 0.473);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(27, 27, 27);
  border-radius: 10px;
}
.text-format {
  width: 2.3ch;
  display: inline-flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}
</style>